<template>
    <div>
        <div style="position: relative; border-bottom: 1px solid #ddd">
            <!-- <div v-if="index == 0" class="py-3">
                                    Danh sách chuyến đi
                                </div> -->
            <div class="row pt-3 pb-2 align-center">
                <div class="font-18 font-bold">
                    {{ item.bienKiemSoat + "\xa0" }}
                </div>
                <div class="font-12">
                    {{ `(Mã chuyến đi: ${item.maChuyenDi})` }}
                </div>
            </div>
            <div class="row pb-2 align-center font-14 justify-space-between">
                <div>Tuyến:</div>
                <div>
                    {{ item.tenLuongTuyen }}
                </div>
            </div>
            <div class="row pb-3 align-center font-14 justify-space-between">
                <div>Thời gian xuất bến:</div>
                <div>
                    {{
                        moment(item.thoiGianXuatBen).format("HH:mm DD/MM/yyyy")
                    }}
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        item: { type: Object, default: () => {} },
    },
    data() {
        return {};
    },
};
</script>