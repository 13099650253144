<template>
    <div>
        <div class="container-xs">
            <DxPieChart
                id="pie"
                :data-source="dataSource"
                palette="Material"
                :redrawOnResize="true"
                :size="{
                    height: '270',
                }"
            >
                <DxSeries argument-field="ten" value-field="tong">
                    <DxLabel
                        :visible="true"
                        :customize-text="formatLabel"
                        position="columns"
                    >
                        <DxConnector :visible="true" :width="0.5" />
                        <DxFont :size="14" />
                    </DxLabel>
                </DxSeries>
                <DxLegend
                    :column-count="4"
                    orientation="horizontal"
                    item-text-position="right"
                    horizontal-alignment="center"
                    vertical-alignment="bottom"
                />
            </DxPieChart>
        </div>
        <div class="divider"></div>
    </div>
</template>
<script>
import { DxPieChart } from "devextreme-vue";
import { DxConnector, DxSeries } from "devextreme-vue/chart";
import { DxFont, DxLabel, DxLegend } from "devextreme-vue/pie-chart";

export default {
    props: {
        dataSource: { type: Array, default: () => [] },
    },
    components: {
        DxPieChart,
        DxSeries,
        DxLabel,
        DxConnector,
        DxFont,
        DxLegend,
    },
    data() {
        return {
            // dataSource: [
            //     {
            //         country: "USA",
            //         medals: 110,
            //     },
            //     {
            //         country: "China",
            //         medals: 100,
            //     },
            //     {
            //         country: "Russia",
            //         medals: 72,
            //     },
            // ],
        };
    },
    methods: {
        formatLabel(pointInfo) {
            return `${pointInfo.valueText} (${pointInfo.percentText})`;
        },
    },
};
</script>
<style lang="scss" scoped>
.divider {
    height: 10px;
    background-color: #fbfbfb;
    border-top: 1px solid $border;
    border-bottom: 1px solid #ececec;
}
</style>