<template>
    <ion-page>
        <ThanhTieuDeVue TieuDe="Báo cáo bán vé cho chuyến đi"
            ><template #after><div></div></template
        ></ThanhTieuDeVue>
        <ion-content :scroll-events="false">
            <DxTabPanel
                :data-source="data.Tabs"
                :loop="false"
                :animation-enabled="true"
                :swipe-enabled="false"
                no-data-text="Không có dữ liệu!"
                class="tabpanel-style"
                :onTitleClick="onTitleClick"
            >
                <template #title="{ data: header }">
                    <span>{{ header.text }}</span>
                </template>
                <template #item="{}">
                    <div>
                        <div class="row align-center ngay-style">
                            <div
                                class="xs6 px-2"
                                style="border-right: 1px solid #ddd"
                            >
                                Chọn ngày xuất bến:
                            </div>
                            <div class="xs6 pl-2">
                                <DxDateBox
                                    v-model="model.NgayXuatBen"
                                    :openOnFieldClick="true"
                                    displayFormat="dd/MM/yyyy"
                                    dropDownButtonTemplate="customIcon"
                                    validationMessageMode="always"
                                    styling-mode="underlined"
                                    pickerType="rollers"
                                    type="date"
                                    :onValueChanged="chonNgay"
                                >
                                    <template #customIcon="{}">
                                        <DxButton icon="mdi mdi-calendar" />
                                    </template>
                                </DxDateBox>
                            </div>
                        </div>
                        <div>
                            <div class="font-bold ml-3 pt-2">
                                {{
                                    `Tổng: ${
                                        model.Tab == 1
                                            ? model.Tong + " vé"
                                            : $MoneyFormat(model.Tong) + "đ"
                                    }`
                                }}
                            </div>
                            <BieuDoVue :dataSource="data.BieuDo" />
                        </div>
                        <DxList
                            :data-source="data.DanhSachChuyenDi"
                            height="calc(100vh - 215px)"
                            class="pt-1 list-style"
                            selection-mode="all"
                            select-all-mode="allPages"
                            :show-selection-controls="true"
                            ref="DanhSachChuyenDi"
                            no-data-text="Không có dữ liệu!"
                            :onSelectionChanged="onSelectionChanged"
                            :onSelectAllValueChanged="onSelectionChanged"
                            v-model:selectedItems="selectedItems"
                        >
                            <template #item="{ data: data }">
                                <div>
                                    <ItemChuyenDiVue :item="data" />
                                </div>
                            </template>
                        </DxList>
                    </div>
                </template>
            </DxTabPanel>
        </ion-content>
    </ion-page>
</template>
<script>
import ThanhTieuDeVue from "../../../src/components/_Common/ThanhTieuDe";
import ItemChuyenDiVue from "../../../src/components/BaoCao/ItemChuyenDi";
import BieuDoVue from "../../../src/components/BaoCao/BieuDo";
import PhuongTien from "../../../class/PhuongTien";
import { IonContent, IonPage } from "@ionic/vue";
import moment from "moment";
import { DxDateBox, DxList, DxTabPanel, DxButton } from "devextreme-vue";
export default {
    components: {
        ThanhTieuDeVue,
        ItemChuyenDiVue,
        BieuDoVue,
        DxTabPanel,
        DxDateBox,
        DxButton,
        DxList,
        IonContent,
        IonPage,
    },
    data() {
        return {
            model: {
                Tab: 1,
                NgayXuatBen: new Date(),
                Tong: 0,
            },
            selectedItems: [],
            data: {
                Tabs: [
                    {
                        id: 1,
                        text: "Tổng số vé bán",
                    },
                    {
                        id: 2,
                        text: "Tiền thu trên xe",
                    },
                ],
                DanhSachChuyenDi: [],
                BieuDo: null,
                DanhSachChuyenDiDangChon: [],
            },
        };
    },
    methods: {
        onTitleClick(e) {
            this.data.BieuDo = [];
            this.data.DanhSachChuyenDi = [];
            this.model.Tong = 0;
            this.model.Tab = e.itemData.id;
            if (e.itemData.id == 1) {
                this.layBaoCaoHoatDongBanVeTrenXe();
            } else if (e.itemData.id == 2) {
                this.layBaoCaoDoanhThuBanVeTrenXe();
            }
        },
        chonNgay() {
            if (this.model.Tab == 1) {
                this.layBaoCaoHoatDongBanVeTrenXe();
            } else if (this.model.Tab == 2) {
                this.layBaoCaoDoanhThuBanVeTrenXe();
            }
        },
        async layBaoCaoHoatDongBanVeTrenXe() {
            try {
                let tuNgay = new Date(
                    this.model.NgayXuatBen.getFullYear(),
                    this.model.NgayXuatBen.getMonth(),
                    this.model.NgayXuatBen.getDate(),
                    0,
                    0,
                    0
                );
                let denNgay = new Date(
                    this.model.NgayXuatBen.getFullYear(),
                    this.model.NgayXuatBen.getMonth(),
                    this.model.NgayXuatBen.getDate(),
                    23,
                    59,
                    59
                );
                let baoCao =
                    await new PhuongTien().layBaoCaoHoatDongBanVeTrenXe(
                        moment(tuNgay).format(),
                        moment(denNgay).format()
                    );
                this.data.DanhSachChuyenDi = baoCao;
            } catch (error) {
                console.error(error);
            }
        },
        async layBaoCaoDoanhThuBanVeTrenXe() {
            try {
                let tuNgay = new Date(
                    this.model.NgayXuatBen.getFullYear(),
                    this.model.NgayXuatBen.getMonth(),
                    this.model.NgayXuatBen.getDate(),
                    0,
                    0,
                    0
                );
                let denNgay = new Date(
                    this.model.NgayXuatBen.getFullYear(),
                    this.model.NgayXuatBen.getMonth(),
                    this.model.NgayXuatBen.getDate(),
                    23,
                    59,
                    59
                );
                let baoCao =
                    await new PhuongTien().layBaoCaoDoanhThuBanVeTrenXe(
                        moment(tuNgay).format(),
                        moment(denNgay).format()
                    );
                this.data.DanhSachChuyenDi = baoCao;
            } catch (error) {
                console.error(error);
            }
        },
        onSelectionChanged() {
            this.tinhTong();
        },
        onSelectAllValueChanged() {
            this.tinhTong();
        },
        tinhTong() {
            if (this.selectedItems.length == 0) {
                this.model.Tong = 0;
                this.data.BieuDo = [];
                return;
            }

            let data = [],
                dsBieuDo = [];
            let tongTatCa = 0;
            this.selectedItems
                .map((e) => e.danhSach)
                .forEach((e) => {
                    e.forEach((ds) => {
                        dsBieuDo.push(ds);
                    });
                });
            dsBieuDo.forEach((e) => {
                let tong = 0;
                dsBieuDo.forEach((f) => {
                    if (e.id == f.id) {
                        tong += f.tong;
                    }
                });
                if (data.length == 0) {
                    data.push({ id: e.id, ten: e.ten, tong: tong });
                }
                let dsId = data.map((m) => m.id);
                if (!dsId.includes(e.id)) {
                    data.push({ id: e.id, ten: e.ten, tong: tong });
                }
                tongTatCa += tong;
            });
            this.data.BieuDo = data;
            this.model.Tong = tongTatCa;
        },
    },
    created() {
        this.layBaoCaoHoatDongBanVeTrenXe();
    },
};
</script>
<style scoped>
>>> .dx-texteditor.dx-editor-underlined::after {
    border-bottom: unset !important;
}
>>> .tabpanel-style .dx-tabs-wrapper,
>>> .tabpanel-style .dx-item.dx-tab.dx-tab-selected {
    background-color: white !important;
}
>>> .tabpanel-style > .dx-tabpanel-tabs > .dx-tabs {
    display: flex;
    justify-content: center;
    background-color: white;
}
>>> .tabpanel-style > .dx-tabpanel-tabs .dx-item.dx-tab {
    background-color: white;
}
>>> .tabpanel-style .dx-tabs-wrapper {
    width: 100%;
    display: flex !important;
}
>>> .tabpanel-style .dx-tabs-wrapper > div {
    width: 50%;
}
>>> .tabpanel-style .dx-tab {
    padding: 6px 16px !important;
}
/* style list */
>>> .dx-item.dx-list-item,
>>> .dx-list-select-all {
    position: relative;
}
>>> .dx-list-select-all-checkbox.dx-show-invalid-badge.dx-checkbox.dx-widget {
    position: absolute;
    right: 19px;
}
>>> .dx-list-item-before-bag.dx-list-select-checkbox-container {
    position: absolute;
    right: 16px;
    top: 10px;
    height: auto;
}
>>> .dx-template-wrapper.dx-item-content.dx-list-item-content {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
}
>>> .dx-list-select-all {
    position: sticky;
    top: 0;
    background-color: white;
    z-index: 1;
    font-weight: bold;
}
>>> .dx-list-select-all-label {
    width: 165px;
    max-width: 165px;
    padding-right: 0px !important;
    text-overflow: unset !important;
}
>>> .dx-list-select-all-label:before {
    content: "Danh sách chuyến đi";
}
</style>
<style lang="scss" scoped>
.ngay-style {
    border-bottom: 1px solid $border;
}
</style>